<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mstfForm')" @cancel="cancel" :stateFlag="false"></add-header>
    <el-form ref="mstfForm" :model="mstfForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="二维码编号" prop="qrcd_no">
            <el-input
              :disabled="disableFlag"
              @keydown.enter.native="inputDown"
              ref="inputs"
              maxlength="10"
              focus
              @input="mstfForm.qrcd_no = helper.keepEngNum(mstfForm.qrcd_no)"
              v-model="mstfForm.qrcd_no"
              placeholder="请填写二维码编号"
              show-word-limit
            >
              <el-button slot="append" type="info" @click="emptyInput" class="vd_btn">清空</el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="潘通色号" prop="mtrl_color">
            <el-input disabled maxlength="20" v-model="mstfForm.mtrl_color" placeholder="请填写潘通色号" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <el-input disabled maxlength="10" v-model="mstfForm.mtrl_no" placeholder="请填写物料编号" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_name" placeholder="请填写物料名称" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-input
              :disabled="true"
              v-if="mstfForm.mtrl_type === 0"
              v-model="mtrlType1"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="true"
              v-else-if="mstfForm.mtrl_type === 1"
              v-model="mtrlType2"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="true"
              v-else-if="mstfForm.mtrl_type === 2"
              v-model="mtrlType3"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input :disabled="true" v-else v-model="mtrlType4" show-word-limit placeholder="暂无物料名称"></el-input>
            <!-- <el-select disabled v-model="mstfForm.mtrl_type" placeholder="请选择物料类型" clearable>
              <el-option
                  v-for="item in mtrlType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料规格" prop="mtrl_spec">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_spec" placeholder="请填写物料规格" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属部门" prop="belo_dept_id">
            <el-select v-model="mstfForm.belo_dept_id" placeholder="请选择所属部门" disabled>
              <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料单位" prop="mtrl_unit">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_unit" placeholder="请选择物料单位" show-word-limit> </el-input>
            <!-- <el-select disabled v-model="mstfForm.mtrl_unit" placeholder="请选择物料单位" clearable>
              <el-option
                v-for="item in mtrlTypeGroupOpt"
                :key="item.id"
                :label="item.param1"
                :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料门幅" prop="mtrl_width">
            <el-input disabled maxlength="30" v-model="mstfForm.mtrl_width" placeholder="请填写物料门幅" show-word-limit>
              <template slot="append">米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="库位" prop="qrcd_bin_no">
            <el-input disabled maxlength="30" v-model="mstfForm.qrcd_bin_no" placeholder="请填写库位" show-word-limit> </el-input>
            <!-- <el-select :disabled="binFlag" v-model="mstfForm.qrcd_bin" placeholder="请选择库位" clearable>
              <el-option
                v-for="item in binList"
                :key="item.id"
                :label="item.param1"
                :value="item.id">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8" v-if="!numFlag">
          <el-form-item label="数量" prop="mtrl_num">
            <el-input
              v-model="mstfForm.mtrl_num"
              @blur="mstfForm.mtrl_num = helper.calcPrice(mstfForm.mtrl_num, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写数量"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" v-if="numFlag">
          <el-form-item label="长度" prop="mstf_length">
            <el-input
              v-model="mstfForm.mstf_length"
              @blur="mstfForm.mstf_length = helper.retain1(mstfForm.mstf_length, 2, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写长度"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" v-if="numFlag">
          <el-form-item label="宽度" prop="mstf_width">
            <el-input
              v-model="mstfForm.mstf_width"
              @blur="mstfForm.mstf_width = helper.retain1(mstfForm.mstf_width, 2, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写宽度"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-form-item label="备注" prop="mstf_remark">
            <el-input type="textarea" :rows="3" v-model="mstfForm.mstf_remark" maxlength="255" show-word-limit placeholder="请填写备注">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mstfAPI } from '@api/modules/mstf';
import { qrcdAPI } from '@api/modules/qrcd';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';

export default {
  name: 'mstfAddMain',
  components: {
    addHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        qrcd_no: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_num: [{ required: true, trigger: 'blur', message: ' ' }],
        mstf_length: [{ required: true, trigger: 'blur', message: ' ' }],
        mstf_width: [{ required: true, trigger: 'blur', message: ' ' }],
        qrcd_bin: [{ required: true, trigger: 'change', message: ' ' }]
      },
      mstfForm: {
        mstf_type: null,
        qrcd_no: null,
        mtrl_id: null,
        mtrl_no: null,
        mtrl_name: null,
        mtrl_unit: null,
        mtrl_type: null,
        mtrl_num: null,
        mtrl_width: null,
        mtrl_color: null,
        mtrl_spec: null,
        qrcd_bin: null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        mstf_length: null,
        mstf_width: null,
        status: 0,
        mstf_remark: null,
        key: 0,
        belo_dept_id: null //所属部门
      },
      binList: [],
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' },
        { id: 3, label: '加工面料' }
      ],
      mtrlTypeGroupOpt: [],
      title: '',
      binFlag: false,
      numFlag: false,
      mtrlType1: '原面料',
      mtrlType2: '辅料',
      mtrlType3: '包材',
      mtrlType4: '',
      disableFlag: false,
      option: [] //所属部门
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {
    this.$refs.inputs.focus();
  },
  methods: {
    initData() {
      this.getMstfType();
      this.getBinList();
      this.getDepartment();
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 })
        .then(res => {
          if (res.data.code === 0) {
            this.binList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMstfType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取打样部门
    getDepartment() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let arr = [];
            res.data.data.form.optn_cntt_list.forEach(item => {
              arr.push({
                value: item.param2,
                label: item.param1
              });
            });
            this.option = arr;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo1();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存出库
    saveInfo1() {
      if (this.mstfForm.qrcd_bin_no) {
        const staffForm = this.$refs.userData.getData();
        this.mstfForm = Object.assign(this.mstfForm, staffForm);
        post(mstfAPI.stockOut, this.mstfForm)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.disableFlag = false;
              const permId = this.$route.query.perm_id;
              this.jump(`/mstf_list?perm_id=${permId}`);
              this.resetForm('mstfForm');
              this.$refs.inputs.focus();
              this.$EventBus.$emit('uploading', 12345);
            } else if (res.data.code === 7) {
              this.$message({
                type: 'error',
                message: '库位中已有此物料'
              });
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            }
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '请输入正确的二维码编号'
            });
            this.resetForm('mstfForm');
          });
      } else {
        this.$message({
          type: 'error',
          message: '该物料暂未入库,请先入库'
        });
      }
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mstfForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 扫码按下enter键导入物料
    inputDown() {
      if (!this.mstfForm.qrcd_no) {
        return this.$message.warning('请先输入二维码编号');
      }
      this.$refs.inputs.blur();
      get(qrcdAPI.getQrcdById, { qrcd_no: this.$refs.inputs.$options.propsData.value })
        .then(res => {
          if (res.data.code === 0) {
            this.disableFlag = true;
            this.mtrlExport(res.data.data.form);
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '库位中已有此物料'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    emptyInput() {
      this.resetForm('mstfForm');
      this.disableFlag = false;
    },
    // 物料出库导入确认
    mtrlExport(val) {
      if (val.mtrl_type === 0 || val.mtrl_type === 3) {
        this.numFlag = true;
      }
      this.mstfForm.qrcd_id = val.qrcd_id;
      this.mstfForm.mtrl_id = val.mtrl_id;
      this.mstfForm.mtrl_no = val.mtrl_no;
      this.mstfForm.mtrl_name = val.mtrl_name;
      this.mstfForm.mtrl_unit = val.mtrl_unit;
      this.mstfForm.mtrl_type = val.mtrl_type;
      this.mstfForm.mtrl_num = val.qrcd_num;
      this.mstfForm.mtrl_spec = val.mtrl_spec;
      this.mstfForm.mtrl_color = val.mtrl_color;
      this.mstfForm.mtrl_width = this.helper.haveFour(val.mtrl_width);
      this.mstfForm.qrcd_bin = val.qrcd_bin;
      this.mstfForm.qrcd_bin_no = val.qrcd_bin_no;
      this.mstfForm.belo_dept_id = val.belo_dept_id + '';
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mstfForm.qrcd_bin = null;
      this.numFlag = false;
      this.mstfForm.mtrl_num = null;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_btn {
  color: #18c7ee !important;
}
</style>
